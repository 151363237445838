import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Pricetag } from "./components/pricetag/pricetag"

import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Popularcity from "./components/location/nearmelink/popularcity";


//import MediaCard from "./components/future/mediacard"

import { green } from '@mui/material/colors';
import Crous2 from "./components/Crous2";
import Chooseus from "./components/chooseus";


import Certifications from "./components/Certifications";
import { Products } from "./components/products/Products"
import Productcrousalmain from "./components/products/Productcrousalmain";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

{/*
const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};
 const fabGreenStyle = {
    color: 'common.white',
    bgcolor: green[500],
    '&:hover': {
      bgcolor: green[600],
    },
  };

*/}


const Home = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
    
  
    return (
      <div>
        <Header data={landingPageData.Header} />
        <Contact data={landingPageData.Contact} />
        {/*<Crous /> 
        <Crous2 /> 
        <Caurousel />
        <Header data={landingPageData.Header} />
        for whatspp web use link href="https://wa.me/9060591201"

        <Productcrousalmain  />
        <Certifications />
        
      */}
        
        <Features data={landingPageData.Features} />
        <About data={landingPageData.About} />
        <Chooseus />
        <Pricetag data={landingPageData.Pricetag} />
        
        
        <Services data={landingPageData.Services} />
        
        <Testimonials data={landingPageData.Testimonials} />
        <Popularcity />
      </div>
    );
  
}


export default Home;
